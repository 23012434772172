export const RSAAS_DEV_ROUTE =
  'https://devrsaaswebservice.apec.psiexams.com/hello';
export const RSAAS_QA3_ROUTE =
  'https://qa3rsaaswebservice.apec.psiexams.com/hello';
export const RSAAS_UAT_ROUTE =
  'https://uatrsaaswebservice.apec.psiexams.com/hello';
export const RSAAS_PROD_ROUTE =
  'https://rsaaswebservice.apec.psiexams.com/hello';
// export const NCARB_DEV_ROUTE =
//   'https://devncarbwebservice.apec.psiexams.com/NCarb/v1/hello';
// export const NCARB_QA3_ROUTE =
//   'https://qa3ncarbwebservice.apec.psiexams.com/NCarb/v1/hello';
// export const NCARB_UAT_ROUTE =
//   'https://uatncarbwebservice.apec.psiexams.com/NCarb/v1/hello';
// export const NCARB_PROD_ROUTE =
//   'https://ncarbwebservice.apec.psiexams.com/NCarb/v1/hello';
